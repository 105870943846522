















































































import { Component, Vue } from 'vue-property-decorator'
import { commitSheet, getQuestions, getUserInfo, updateSheet } from '@/api/student'
import { ExamRecord, ExamResult, ExamResultType, Question } from '@/api/type'
import { UserModule } from '@/store/modules/user'
import ExamCountDown from '@/components/ExamCountDown.vue'

@Component({
  components: { ExamCountDown },
  filters: {
    qtype: (type: number) => {
      const qtypeMap: { [key: number]: string } = {
        1: '单选题',
        2: '多选题(漏选或错选不得分)',
        3: '判断题',
      }
      return qtypeMap[type]
    },
  },
})
export default class Home extends Vue {
  questions: Question[] = []
  index = 0
  student: ExamRecord | null = null
  result: ExamResult | null = null

  get question() {
    if (this.index < this.questions.length) {
      return this.questions[this.index]
    }
    return null
  }

  get answer() {
    return this.questions.map((q) => {
      const v = q.checkValues.length > 0 ? q.checkValues.join('|') : q.value
      return { id: q.id, value: v }
    })
  }

  get numAnswered() {
    return this.answer.filter((q) => q.value && q.value.length > 0).length
  }

  first() {
    this.index = 0
    this.syncAnswer()
  }

  prev() {
    if (this.index > 0) {
      this.index--
      this.syncAnswer()
    }
  }

  next() {
    if (this.index < this.questions.length - 1) {
      this.index++
      this.syncAnswer()
    }
  }

  last() {
    this.index = this.questions.length - 1
    this.syncAnswer()
  }

  goto(index: number) {
    this.index = index
    this.syncAnswer()
  }

  created() {
    this.fetch()
  }

  fetch() {
    getUserInfo().then((rsp) => {
      this.student = rsp.data as ExamRecord
    })
    getQuestions().then((rsp) => {
      if (rsp.data) {
        const qs = rsp.data as Question[]
        qs.forEach((q) => {
          q.options = []
          Object.keys(q.content).forEach((key) => {
            q.options.push({ label: key, name: q.content[key] })
            q.checkValues = []
          })
        })
        this.questions = qs
      }
    })
  }

  count = 30
  timer = 0
  tryCommit() {
    if (this.numAnswered != this.questions.length) {
      this.$confirm('未完成答卷，是否交卷?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.commit()
        })
        .catch(() => {
          console.log('取消提交，继续答题')
        })
    } else {
      this.commit()
    }
  }

  commit() {
    console.log('commit', this.answer)
    commitSheet({ answers: this.answer })
      .then((rsp) => {
        if (rsp.data) {
          const r = rsp.data as ExamResult
          this.result = {
            ...r,
            resultStamp: r.result == ExamResultType.Pass ? '/img/pass.jpg' : '/img/fail.jpg',
            resultFace: r.result == ExamResultType.Pass ? '/img/passFace.jpg' : '/img/failFace.jpg',
          }
          this.result
          this.timer = setInterval(() => {
            this.countdown()
          }, 1000)
        }
      })
      .catch(() => {
        this.$message.warning('交卷失败，请稍后重试')
      })
  }

  answerChanged = false
  onAnswerChanged() {
    this.answerChanged = true
  }

  syncAnswer() {
    if (!this.answerChanged) {
      return
    }

    this.answerChanged = false
    console.log('update', this.answer)
    updateSheet({ answers: this.answer })
      .then((rsp) => {
        console.log('同步答卷成功', rsp)
      })
      .catch((e) => console.log('同步答卷失败', e))
  }

  countdown() {
    if (this.count > 0) {
      this.count--
    } else {
      clearInterval(this.timer)
      this.logout()
    }
  }

  logout() {
    UserModule.LogOut().then(() => {
      this.$router.push({ path: '/login' }).catch((e) => console.log('退出跳转失败', e))
    })
  }

  onTimeIsUp() {
    this.commit()
  }
}
