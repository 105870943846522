export interface Question {
  id: number
  type: number
  title: string
  content: { [key: string]: string }
  options: { label: string; name: string }[]
  value: string
  checkValues: string[]
}

export interface Paper {
  id: number
  planId: number
  subjectId: number
  questions: Question[]
}

export interface Student {
  id: number
  name: string
  idCard: string
  admission: string
  subjectId: number
  operationType: string
  operationItem: string
  planId: number
  planName: string
}

export enum ExamResultType {
  Pass = 1,
  Fail = 2,
}

export interface ExamResult {
  result: ExamResultType
  score: number
  resultStamp?: string
  resultFace?: string
}

export interface ExamRecord {
  id: number
  name: string
  idCard: string
  planId: number
  planName?: string
  subjectId: number
  subjectName?: string
  subjectCode?: string
  score: number
  result: number
  operationScore: number
  operationResult: number
}
