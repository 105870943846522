























import { syncLeftTime } from '@/api/student'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class extends Vue {
  left: number | null = null
  isStop = false
  errTimes = 0
  loading = false

  get leftString() {
    if (!this.left) {
      return ''
    }
    if (this.left > 60) {
      return Math.floor(this.left / 60) + '分钟'
    }
    return this.left + '秒'
  }

  get leftClass() {
    if (!this.left || this.left > 60) {
      return ''
    }
    return 'warning'
  }

  mounted() {
    setInterval(this.countdown, 1000)
    this.sync()
  }

  countdown() {
    if (!this.left) {
      return
    }
    if (!this.isStop) {
      this.left--
      if (this.left == 0) {
        this.$emit('timeisup')
      }
    }
  }

  sync() {
    this.loading = true
    syncLeftTime()
      .then((rsp) => {
        if (rsp.data && rsp.data.left) {
          this.left = rsp.data.left
          this.errTimes = 0
          this.isStop = false
          setTimeout(this.sync, 30 * 1000)
        } else {
          this.onSyncError()
        }
      })
      .catch(() => {
        this.onSyncError()
      })
      .finally(() => (this.loading = false))
  }

  onSyncError() {
    this.errTimes++
    if (this.errTimes >= 3) {
      this.isStop = true
    } else {
      setTimeout(this.sync, 3000)
    }
  }
}
